




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { deleteUserUseCase } from '@/core/admin/usecases/deleteUserUseCase';
import type { User } from '@/core/admin/models/User';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';

@Component({
  name: 'DeleteUserDialog',
})
export default class DeleteUserDialog extends Vue {
  @Prop() userId!: string;
  @Prop() currentUser!: User;
  @Prop({ type: String, required: true }) name: string | unknown;
  @Prop({ type: Boolean }) showDialog!: boolean;
  private isLoading = false;

  async deleteUser() {
    if (this.userId) {
      this.isLoading = true;
      deleteUserUseCase(this.currentUser, { userId: this.userId })
        .then(() => {
          this.closeDialog();
          this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
            message: 'Admin.delete-user.delete-success-alert',
            type: AlertType.SUCCESS,
          });
          this.$emit('deleted');
        })
        .catch(() => {
          this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
            message: 'Admin.delete-user.delete-error-alert',
            type: AlertType.ERROR,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  closeDialog() {
    this.$emit('close');
  }
}
