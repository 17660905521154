var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"jls-data-table",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"page":_vm.page,"hide-default-footer":"","items-per-page":10},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.lastName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.firstName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.role",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.permissions",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.lastSeen",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.isActive",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getTranslationIdentifierFromUserRole(item.role)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-admin-icons-wrapper"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEditUser(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"right":"","color":"accent"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-edit')))])])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteUser(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"right":"","color":"accent"}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-delete')))])])],1)],1)]}},{key:"item.lastSeen",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("isoTimestampToLocalizedShortDate")(item.lastSeen)))])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-check ")])],1):_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)}),_c('UserDialog',{attrs:{"show-dialog":_vm.showEditUserDialog,"user":_vm.selectedUser,"is-edit-user":true},on:{"updated":_vm.refreshTable,"close":_vm.closeEditUserDialog}}),_c('DeleteUserDialog',{attrs:{"show-dialog":_vm.showDeleteUserDialog,"name":((_vm.selectedUser.firstName) + " " + (_vm.selectedUser.lastName)),"current-user":_vm.currentUser,"user-id":_vm.selectedUser.id},on:{"close":_vm.closeDeleteUserDialog,"deleted":_vm.refreshTable}}),_c('div',{staticClass:"pagination-wrapper"},[_c('v-pagination',{staticClass:"jls-data-table-footer",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }