import type { User } from '@/core/admin/models/User';
import { HeaderInfo, UserDataForTable, UserEntryInTable } from '@/core/admin/models/UserDataTable';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import { Languages } from '@/core/admin/constants/Languages.const';

function getEmptyUser(): User {
  return {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    permissions: [],
    role: USER_ROLE.USER,
    customerId: '',
    language: Languages.DE,
    isActive: false,
  };
}

function generateHeaders(): Array<HeaderInfo> {
  return [
    { text: 'Admin.user.last-name', value: 'lastName' },
    { text: 'Admin.user.first-name', value: 'firstName' },
    { text: 'Admin.user.email', value: 'email' },
    { text: 'Admin.user.user-group', value: 'role' },
    { text: 'Admin.user.permissions', value: 'permissions' },
    { text: 'Admin.user.status', value: 'isActive' },
    { text: '', value: 'actions' },
  ];
}

function createEntryFromUser(user: User): UserEntryInTable {
  return {
    id: user.id,
    lastName: user.lastName,
    email: user.email,
    firstName: user.firstName,
    permissions: user.permissions,
    isActive: user.isActive,
    role: user.role,
  };
}

function generateValues(users: Array<User>): Array<UserEntryInTable> {
  const usersForTable: Array<UserEntryInTable> = [];
  users.forEach((user) => {
    usersForTable.push(createEntryFromUser(user));
  });
  return usersForTable;
}

function generateTableDataFromUsers(users: Array<User>): UserDataForTable {
  return {
    headers: generateHeaders(),
    values: generateValues(users),
  };
}

export { getEmptyUser, generateTableDataFromUsers };
