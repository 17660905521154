






























































































import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { getTranslationIdentifierFromUserRole } from '@/core/admin/constants/UserRoles.const';
import DeleteUserDialog from '@/ui/components/organisms/dialogs/DeleteUserDialog.vue';
import UserDialog from '@/ui/components/organisms/dialogs/UserDialog.vue';
import { HeaderInfo } from '@/core/admin/models/UserDataTable';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import type { User } from '@/core/admin/models/User';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';
import { Loader } from '@/ui/util/decorators/loader';
import { getEmptyUser } from '@/ui/services/admin/userDataService';

@Component({
  name: 'admin-user-data-table',
  components: { UserDialog, DeleteUserDialog },
})
export default class AdminUserDataTable extends Vue {
  @Prop(String) readonly search: string | undefined;
  @Prop() currentUser!: CurrentUser;
  private selectedUser: User = getEmptyUser();
  private showEditUserDialog = false;
  private showDeleteUserDialog = false;

  page = 1;
  pageCount = 0;

  mounted() {
    this.$store.dispatch('adminStore/getAllUsers');
  }

  @Watch('isNewUserCreated')
  onNewUser(val: boolean) {
    if (val) {
      this.$store.commit(AdminStoreMutations.SET_IS_NEW_USER_CREATED, false);
      this.refreshTable();
    }
  }

  get users(): User[] {
    return this.$store.state.adminStore.users;
  }

  get headers(): Array<HeaderInfo> {
    return [
      { text: this.$t('Admin.user.last-name'), value: 'lastName' },
      { text: this.$t('Admin.user.first-name'), value: 'firstName' },
      { text: this.$t('Admin.user.email'), value: 'email' },
      { text: this.$t('Admin.user.user-group'), value: 'role' },
      { text: this.$t('Admin.user.permissions'), value: 'permissions' },
      { text: this.$t('Admin.user.last-seen'), value: 'lastSeen' },
      { text: this.$t('Admin.user.status'), value: 'isActive' },
      { text: '', value: 'actions' },
    ];
  }

  get getTranslationIdentifierFromUserRole() {
    return getTranslationIdentifierFromUserRole;
  }

  get isNewUserCreated() {
    return this.$store.state.adminStore.isNewUserCreated;
  }

  @Loader
  async refreshTable() {
    await this.$store.dispatch('adminStore/getAllUsers');
  }

  onEditUser(user: User) {
    this.selectedUser = user;
    this.showEditUserDialog = true;
  }

  onDeleteUser(user: User) {
    this.selectedUser = user;
    this.showDeleteUserDialog = true;
  }

  closeEditUserDialog() {
    this.showEditUserDialog = false;
  }

  closeDeleteUserDialog() {
    this.showDeleteUserDialog = false;
  }
}
