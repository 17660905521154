









import { Component, Vue } from 'vue-property-decorator';
import AdminUserDataTable from '@/ui/components/molecules/tables/AdminUserDataTable.vue';
import SearchField from '@/ui/components/molecules/inputs/SearchField.vue';
import type { CurrentUser } from '@/core/user/models/CurrentUser';

@Component({
  name: 'AdminUserTab',
  components: { SearchField, AdminUserDataTable },
})
export default class AdminUserTab extends Vue {
  search = '';
  page = 1;
  pageCount = 0;

  get currentUser(): CurrentUser | undefined {
    return this.$store.state.userStore.currentUser;
  }

  updateSearch(query: string) {
    this.search = query;
  }
}
